import React from "react";
import { Helmet } from "react-helmet";
import ContactComponent from "../components/Contact";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";

const JapanDisclaimerPage: React.FC = () => {
  const japanContent = (
    <div className="mb-40">
      <div className="mb-4 text-center text-base">適格機関投資家等特例業務に関する公衆縦覧について</div>
      <div className="mb-3">
        <br></br>下記に掲げる当グループの関連会社（以下、「ジェネラル・パートナー」といいます。）は、その行う金融
        <br></br>商品取引法（以下、「金商法」といいます。）第６３条に規定される適格機関投資家等特例業務（以下、
        <br></br>「特例業務」といいます。）につきまして、金商法第６３条第６項及び第６３条の４第３項に基づき、所定
        <br></br>の書面（以下、「開示書面」といいます。）を公衆の縦覧に供することが求められています。
      </div>
      <div className="mb-3">
        これに関して、当社は、ジェネラル・パートナーからの委託を受けて、開示書面の公衆縦覧のための手
        <br></br>続きを行います（当社自体が特例業務を行うものではありません）。
      </div>
      <div className="mb-3">
        開示書面の閲覧をご希望の方は、下記までご連絡ください。
      </div>
      <div className="mb-4">
        Compliance: &nbsp;
        <a href="mailto:complianceasia@bamfunds.com" target="_blank" className="text-blue-light">
          complianceasia@bamfunds.com
        </a>
      </div>
      <div className="grid grid-cols-2 border mb-4">
        <div className="border-r border-b p-2">特例業務を行うジェネラル・パートナーの名称</div>
        <div className="border-b p-2">ファンド名</div>
        <div className="border-r p-2">BAM Elevate GP, LLC</div>
        <div className="p-2">BAM Elevate (Cayman) Fund, LP</div>
      </div>
      <div className="mb-3">
        <strong>重要</strong>
        <br></br>提供させていただく開示書面は情報提供のみを目的としており、投資助言を構成するものではないこ
        <br></br>とにご留意ください。また、開示書面に含まれる情報は、日本に居住されている方に対して、日本の適
        <br></br>用法令に違反することとなる有価証券、金融商品その他のサービスの販売の申し出又は購入の申し
        <br></br>込みの勧誘を行うことを意図するものではありません 。
      </div>
    </div>
  );

  const englishContent = (
    <div className="max-w-[760px]">
      <div className="mb-4 flex items-center justify-center">
        <div className="max-w-[500px] text-center text-base">Public Disclosures regarding Specially Permitted Businesses for
          Qualified Institutional Investor, etc.</div>
      </div>
      <div className="mb-3">
        Pursuant to Article 63, Paragraph 6 and Article 63-4, Paragraph 3 of the Financial Instruments and Exchange Act of Japan (the "FIEA"), our group affiliated entities listed below (each a "General Partner") are required to make public disclosures regarding certain documents ("Disclosure Documents") in connection with such General Partner's operation under the Exemption Specially Permitted Businesses for Qualified Institutional Investor, etc as set forth under the Article 63 of the FIEA (the "Article 63 Exemption").
      </div>
      <div className="mb-3">
        It should be noted that each General Partner has delegated both the disclosure and maintenance of such Disclosure Documents to us and, for the avoidance of doubt, we do not operate any own activities under the Article 63 Exemption.
      </div>
      <div className="mb-3">
        Any person that wishes to receive a copy of such Disclosure Documents, please kindly contact the following email:
      </div>
      <div className="mb-4">
        Compliance: &nbsp;
        <a href="mailto:complianceasia@bamfunds.com" target="_blank" className="text-blue-light">
          complianceasia@bamfunds.com
        </a>
      </div>
      <div className="grid grid-cols-2 border mb-4">
        <div className="border-r border-b p-2">Name of General Partner operating under the Article 63 Exemption</div>
        <div className="border-b p-2">Name of the Limited Partnership Fund</div>
        <div className="border-r p-2">BAM Elevate GP, LLC</div>
        <div className="p-2">BAM Elevate (Cayman) Fund, LP</div>
      </div>
      <div className="mb-3">
        <strong>IMPORTANT</strong>
        <div>
          Any disclosure of the Disclosure Documents is intended solely for informational purposes only and is not intended to constitute the provision of investment advice.  Furthermore, any information contained in the Disclosure Documents does not constitute, is not intended to be and should not be construed as an offer to sell nor a solicitation of any offer to buy any type of securities, financial products or other services to any person in Japan to whom such offer would be unlawful under the applicable laws of Japan.
        </div>
      </div>
    </div>
  )
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Japan Disclaimer" />
        <title>適格機関投資家等特例業務に関する公衆縦覧について</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />
        <PageContent className="md:pt-32 flex flex-col items-center">
          {japanContent}
          {englishContent}
        </PageContent>

        <PageContent>
          <Footer />
        </PageContent>

      </main>
    </>
  )
}

export default JapanDisclaimerPage;